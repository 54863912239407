var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { createContext, useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogin, useLoginExternal, useLoginVas } from 'api/auth';
import { useGetMe } from 'api/user';
import { ReactComponent as ErrorSVG } from 'assets/icons/snackbar/error.svg';
import { useExternalWallet, useSnackbar } from 'hooks';
import { useStore } from 'stores';
import { GAHandler } from 'utils/GA';
export var sessionContext = createContext({
    isLogging: false,
    isPreLoggedIn: false,
    isLoggedIn: false,
    isFreshLogIn: false,
    method: '',
    secret: '',
    login: function () { return Promise.resolve(); },
    loginExternal: function () { return Promise.resolve(); },
    loginVas: function () { return Promise.resolve(); },
    loginError: false,
    logout: function () { return Promise.resolve(); },
    loginWithSecret: function () { return Promise.resolve(); },
});
sessionContext.displayName = 'SessionContext';
var SessionProvider = function (_a) {
    var children = _a.children;
    var t = useTranslation().t;
    var Snackbar = useSnackbar().Snackbar;
    var _b = useStore(), secret = _b.secret, setSecret = _b.setSecret, setIsSeriesCreator = _b.setIsSeriesCreator;
    var isReadyToLogIn = !!secret;
    var _c = useState(isReadyToLogIn), isLoggedIn = _c[0], setIsLoggedIn = _c[1];
    var _d = useState(false), isPreLoggedIn = _d[0], setIsPreLoggedIn = _d[1];
    var _e = useState(false), isLogging = _e[0], setIsLogging = _e[1];
    var _f = useState(false), isFreshLogIn = _f[0], setIsFreshLogIn = _f[1];
    var _g = useState(''), method = _g[0], setMethod = _g[1];
    var queryClient = useQueryClient();
    var _h = useLogin(), login = _h.login, loginData = _h.data, loginError = _h.error, resetLogin = _h.reset;
    var _j = useLoginExternal(), loginExternal = _j.loginExternal, loginExtData = _j.data, loginExtError = _j.error, resetLoginExt = _j.reset;
    var _k = useLoginVas(), loginVas = _k.mutateAsync, loginVasData = _k.data, loginVasError = _k.error, resetLoginVas = _k.reset;
    var _l = useGetMe(), meData = _l.data, meError = _l.error, refetch = _l.refetch, remove = _l.remove;
    var _m = useExternalWallet(), isConnected = _m.isConnected, disconnect = _m.disconnect;
    var handleLogin = function (_a) {
        var email = _a.email, password = _a.password;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsLogging(true);
                        if (!isPreLoggedIn) return [3 /*break*/, 2];
                        /**
                         * User pre-logged means that we have all data
                         * but user is not logged, so we just invalidate /me
                         * in order to update user.status and check it again.
                         */
                        // await queryClient.invalidateQueries(['me'])
                        remove();
                        return [4 /*yield*/, refetch()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                    case 2:
                        setMethod('credentials');
                        login({ email: email, password: password });
                        return [2 /*return*/];
                }
            });
        });
    };
    var loginWithSecret = function (newSecret) {
        setIsLogging(true);
        setMethod('secret');
        setSecret(newSecret);
    };
    var handleLoginExternal = function (_a) {
        var provider = _a.provider, token = _a.token;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsLogging(true);
                        setMethod(provider);
                        return [4 /*yield*/, loginExternal({ provider: provider, token: token })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var handleLoginVas = function (_a) {
        var provider = _a.provider, token = _a.token;
        setIsLogging(true);
        setMethod('vas');
        loginVas({ provider: provider, token: token });
    };
    var internalLogin = function () {
        if (!(meData === null || meData === void 0 ? void 0 : meData.isConfirmed)) {
            setIsPreLoggedIn(true);
        }
        else {
            setIsLoggedIn(true);
            setIsFreshLogIn(true);
            window.localStorage.setItem('secret', secret);
        }
        setIsLogging(false);
    };
    var logout = function () {
        if (isConnected)
            disconnect();
        queryClient.removeQueries(['me']);
        queryClient.removeQueries(['balance']);
        queryClient.removeQueries(['purchases']);
        queryClient.removeQueries(['suggestions_series']);
        queryClient.removeQueries(['all-rewards']);
        queryClient.removeQueries(['new-rewards']);
        queryClient.removeQueries(['rewards']);
        queryClient.removeQueries(['profile']);
        localStorage.removeItem('secret');
        localStorage.removeItem('lang');
        resetLogin();
        resetLoginExt();
        setSecret('');
        setIsLogging(false);
        setIsLoggedIn(false);
        setIsPreLoggedIn(false);
        setIsFreshLogIn(false);
    };
    useEffect(function () {
        if (!meError)
            return;
        logout();
        Snackbar.open({
            icon: ErrorSVG,
            message: t('error:api.unexpected'),
        });
    }, [meError]);
    /**
     * STEP 2: LOGIN DATA
     * This runs after the login executed by the user
     */
    useEffect(function () {
        var _a;
        var data = (_a = loginData !== null && loginData !== void 0 ? loginData : loginExtData) !== null && _a !== void 0 ? _a : loginVasData;
        if (!data)
            return;
        setSecret(data.secret);
    }, [loginData, loginExtData, loginVasData]);
    /**
     * STEP 3: ME DATA
     * when me data comes:
     *  - we set i18n lang and invalidate queries to get them in user lang
     *  - add extra data to user and we save it in the state (context)
     */
    useEffect(function () {
        if (!meData || !isLogging)
            return;
        var data = loginData !== null && loginData !== void 0 ? loginData : loginExtData;
        // TODO: mutate react-query cache instead save it on localStorage
        var userData = __assign(__assign({}, meData), { isModerator: data === null || data === void 0 ? void 0 : data.isModerator, isSerieCreator: data === null || data === void 0 ? void 0 : data.seriesCreator });
        var isDeleting = meData.isDeleting, isDeleted = meData.isDeleted, isBanned = meData.isBanned;
        if (isDeleting || isDeleted || isBanned) {
            logout();
            Snackbar.open({
                icon: ErrorSVG,
                message: t('notPossibleLogInThisAccount'),
            });
            return;
        }
        if (userData.isSerieCreator) {
            localStorage.setItem('isSeriesCreator', String(true));
            setIsSeriesCreator(true);
            GAHandler('login_creator');
        }
        else {
            localStorage.removeItem('isSeriesCreator');
            setIsSeriesCreator(false);
        }
        internalLogin();
    }, [meData, isLogging]);
    useEffect(function () {
        if (loginError || loginExtError || loginVasError) {
            setIsLogging(false);
        }
    }, [loginError, loginExtError, loginVasError]);
    useEffect(function () {
        if (isLogging && loginError)
            resetLogin();
        if (isLogging && loginExtError)
            resetLoginExt();
        if (isLogging && loginVasError)
            resetLoginVas();
    }, [isLogging, loginError, loginExtError, loginVasError]);
    var value = {
        isLogging: isLogging,
        isPreLoggedIn: isPreLoggedIn,
        isLoggedIn: isLoggedIn,
        isFreshLogIn: isFreshLogIn,
        method: method,
        secret: secret,
        login: handleLogin,
        loginExternal: handleLoginExternal,
        loginVas: handleLoginVas,
        loginError: loginError || loginExtError || loginVasError,
        logout: logout,
        loginWithSecret: loginWithSecret,
    };
    return (_jsx(sessionContext.Provider, { value: value, children: children }));
};
export default SessionProvider;

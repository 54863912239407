var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useApi, useSnackbar } from 'hooks';
import { GAHandler } from 'utils/GA';
import { RECAPTCHA_TYPES } from 'config/constants';
import { ReactComponent as ErrorSVG } from 'assets/icons/snackbar/error.svg';
var useLogin = function () {
    var t = useTranslation().t;
    var apiFetch = useApi().apiFetch;
    var Snackbar = useSnackbar().Snackbar;
    var url = '/auth/login';
    var login = function (_a) {
        var email = _a.email, password = _a.password;
        return (apiFetch({
            url: url,
            method: 'POST',
            data: {
                email: email,
                password: password,
            },
            captcha: {
                type: RECAPTCHA_TYPES.v3,
                action: 'login_web',
            },
        })
            .then(function (resLogin) {
            GAHandler('login_flixxo');
            return resLogin.data;
        })
            .catch(function (err) {
            GAHandler('login_flixxo_error');
            if (err.code === 401) {
                Snackbar.open({
                    icon: ErrorSVG,
                    message: t('wrongCredentials'),
                });
            }
            throw err;
        }));
    };
    var _a = useMutation(login), mutate = _a.mutate, rest = __rest(_a, ["mutate"]);
    return __assign({ login: mutate }, rest);
};
export default useLogin;

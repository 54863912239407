const en = {
  common: {
    value: 'Value',
    ok: 'Ok',
    needHelp: 'Need help?',
    contents: 'Contents',
    all: 'All',
    microseries: 'Microseries',
    shorts_one: 'Short Film',
    shorts_other: 'Short Films',
    movies_one: 'Movie',
    movies_other: 'Movies',
    microshorts_one: 'Microshort',
    microshorts_other: 'Microshorts',
    following: 'Following',
    follow: 'Follow',
    cancel: 'Cancel',
    close: 'Close',
    continue: 'Continue',
    logout: 'Log out',
    save: 'Save',
    confirm: 'Confirm',
    edit: 'Edit',
    seasons_short: 'S',
    seasons_one: 'Season',
    seasons_other: 'Seasons',
    episodes_short: 'E',
    episodes_one: 'Episode',
    episodes_other: 'Episodes',
    creator: 'Creator',
    watchNow: 'Watch now',
    back: 'Back',
    finish: 'Finish',
    linkCopied: 'Link copied!',
    support_creators: 'Support',
    new: 'New',
    home: 'Home',
    conecting: 'Connecting',
    showMore: 'Show more',
    none: 'None',
    subtitles: 'Subtitles',
    createdBy: 'by',
    sec: 'sec',
    share: 'Share',
    status: {
      pending: 'Pending',
      confirmed: 'Confirmed',
      cancelled: 'Cancelled',
    },
    gender: {
      male: 'Male',
      female: 'Female',
      other: 'Other',
    },
  },
  vouchers: {
    link: 'Redeem your voucher',
    title: 'Vouchers',
    description: {
      default: 'Enter your voucher to obtain the associated content',
      owned: 'The redeemed content is now permanently in your account',
    },
    redeem: 'Redeem',
    success: 'You redeemed your voucher and received content!',
    errors: {
      invalid: 'The code is not valid. It may be expired, already used, or the content may not yet be available. Please try again later or contact us at info@flixxo.com',
      owned: 'You already have the content associated with this voucher',
    },
  },
  ads: {
    error: 'There was an error loading the ad. Please try again later.',
  },
  profile: {
    title: 'Edit profile',
    description: 'Edit your nickname, profile image and your bio',
    nickname: 'Your nickname',
    nicknameValidation: 'Should be 3 to 16 characters long with at least 1 letter.',
    bioPlaceholder: 'Share something about yourself...',
    optionsTitle: 'Edit profile picture',
    optionsChoose: 'Choose from device',
    optionsTake: 'Take a picture',
    optionsDelete: 'Delete a picture',
    takeDescription: 'Snap a picture for your profile photo',
    confirmTitle: 'Confirm profile picture',
    confirmDescription: 'First, make any necessary edits to the image according to your preferences. If you\'re not content, feel free to take another one.',
    confirmReTake: 'Take another picture',
    toastSuccess: 'The changes were saved successfully',
    toastError: 'Changes couldn\'t be saved, please try again later',
    toastSuccessError: 'Some changes couldn\'t be saved, please try again later',
    toastNickname: 'The nickname you entered is already taken.',
  },
  favorites: {
    link: 'Favorite creators',
    title: 'Your favorite Creators',
    placeholder: 'Search...',
    empty: {
      title: 'Pick your favorite creators',
      description: 'From the preview of a content, you can see its creator and mark them as a favorite to not miss anything.',
    },
  },
  userInfo: {
    title: 'Personal information',
    description: 'Your personal data is not displayed on your Profile',
    message: 'To improve your experience on Flixxo, please complete these details.',
    fullname: 'Full Name',
    birthdate: 'Birthdate',
    gender: 'Gender',
    country: 'Country of residence',
    successMsg: ' Changes were saved successfully',
  },
  challenges: {
    inviteMsg: 'Come check out Flixxo! Discover the best micro-series and short films. Register using the link and earn your first Flixx: play.flixxo.com/referrer/{{nickname}}',
    title: 'Challenges',
    description: 'Earn Flixx by completing challenges',
    availables: 'Available',
    completed: 'Completed',
    invite: 'Invite',
    claim: 'Claim',
    claimed: 'Claimed',
    newRegisters_zero: 'No new registers',
    newRegisters_one: '1 new register',
    newRegisters_other: '{{count}} new registers',
    watchVideo: 'Watch video',
    emptyMsg: 'You still haven\'t ticked off any challenges yet :(',
    toast: {
      button: 'View challenges',
      message: {
        pending: 'You have pending rewards to claim!',
        new: 'You have completed a new challenge!',
      },
    },
  },
  search: {
    placeholder: 'Search in Flixxo',
    results: 'Your results for: ',
    notFound_title: 'No results found :(',
    notFound_opt_1: 'Try looking for another microseries or short Film',
    notFound_opt_2: 'Modify the content type filters',
    notFound_opt_3: 'Use keywords',
    notFound_opt_4: 'Enter the name of actors or genres',
    moods: {
      title: 'Search content based on your mood',
      cry: 'Cry',
      love: 'Fall in love',
      laugh: 'Laugh',
      imagine: 'Imagine',
      shout: 'Shout',
      think: 'Think',
    },
  },
  content: {
    allContents: 'All the contents',
    onlyMicroseries: 'Only microseries',
    onlyShorts: 'Only short films',
    onlyMovies: 'Only movies',
    onlyMicroshorts: 'Only microshorts',
  },
  creators: {
    seeViewBy: 'See view by:',
    balance: {
      quote: 'Flixx quote',
      value: 'Value of your Flixx',
      withdraw: 'Withdraw Flixx',
      withdrawals: 'Withdrawals status',
    },
    modalWithdraw: {
      title: 'Withdraw Flixx',
      description: 'Easily send the Flixx you have accumulated to your wallet',
      step1: {
        connectWallet: 'Connect wallet',
        walletConnected: 'Wallet connected',
        disconnect: 'Disconnect',
        available: 'Available',
        withdrawTotal: 'Withdraw total',
        howMany: 'How many Flixx do you want to withdraw?',
        minLimit: 'Minimum amount to withdraw 1,000 Flixx',
        maxLimit: 'Maximum amount to withdraw 100,000 Flixx',
      },
      step2: {
        summary: 'Summary',
      },
      step3: {
        title: 'Flixx withdrawal successfully completed!',
      },
      info: {
        amount: 'Amount on Flixx',
        wallet: 'Destination wallet',
        gas: 'The <strong>GAS</strong> of the transaction is on us ;)',
        keepInMind: 'Keep the following in mind:',
        bullet1: 'Within 72 business hours you will receive confirmation of the operation by email.',
        bullet2: 'While we transfer the Flixx amount, you will not see it in your balance.',
        bullet3: 'You will see the tracking of the transaction status from the <btn>withdrawal status.</btn>',
      },
    },
    modalWithdrawals: {
      title: 'Withdrawal status',
      description: 'See the status of the Flixx withdrawals made',
      dateTime: 'DATE AND TIME',
      details: 'WITHDRAWAL DETAILS',
      status: 'STATUS',
      flixxTitle: 'Flixx withdrawn',
    },
  },
  modalOnboarding: {
    title: 'A little welcome gift!',
    description: 'We made a short video for you to get the most out of Flixxo. Plus, when it\'s done, you\'ll earn Flixx.',
    cta: 'Watch the welcome video',
  },
  lang: {
    changeMessage: 'We adapt the content to the selected language.',
  },
  anteroom: {
    loginToWatch: 'Log in to Flixxo to watch the content',
    watchFor: 'Watch for <0></0>',
    similar: 'Similar contents',
    adMsg: 'An ad will pop up, and you\'ll earn Flixx',
    creatorPayment: '<yellow>{{creator}}</yellow> got your Flixx!',
    keepGoingWith: 'Now, keep going with...',
    seen: 'Seen',
    unsupportedByDRM: 'Browser not supported',
    withFlixx: 'WITH FLIXX',
    withToken: 'TOKEN GATING / TICKET 3.0',
    downloadApp: 'Download the app to add Flixx',
    haveTheFlixx: 'You\'ve got the Flixx you need!',

    tokenModal: {
      title: 'Access the content with:',
      warning: 'You must have at least one token/ticket 3.0 in your wallet to access the content',
    },
    series: {
      watchNextEp: 'Watch next episode',
    },
    postroll: {
      title: 'Some of this\n you\'re gonna like!',
    },
    error: {
      retrieving: {
        title: 'Error retrieving content',
      },
      buyContent: 'The purchase of the content could not be completed',
    },
  },
  error: {
    default: {
      title: 'Unexpected Error',
      description: 'Reload the page and try again.',
    },
    player: {
      playback: {
        title: 'Playback error',
        description: 'An error has occurred in the content playback',
      },
    },
    api: {
      unexpected: 'Unexpected error. Reload the page and try again.',
      tooManyRequests: 'Too many tries. Try again later or get in touch with us.',
      humanVerification: 'We can\'t fulfill your request. Try again later or get in touch with us.',
    },
  },
  modals: {
    downloadApp: {
      getTheMostOut: 'Discover how the get the most out of Flixxo!',
      fullExperience: {
        title: 'Download the Flixxo App!',
        description: 'Enjoy all your favorite microseries and short films anytime, anywhere!',
      },
      buyFlixxApp: {
        title: 'Download the app and get Flixx!',
        description: 'In the app, you can take advantage of various Flixx offers we have for you.',
      },
      wallet: {
        title: 'Download the app and donate flixx to your favourite creators!',
        description: 'In the app you will have a wallet which you can use to transfer flixx to creators and users',
      },
    },
    welcome: {
      title: 'Register now and get free Flixx!',
      description: 'The best indie content from creators around the world',
      enterWith: 'Enter with',
      alsoYouCan: 'Or also you can',
      prepaid: 'Do you have a Flixxo prepaid account?',
    },
    register: {
      nickname: 'Nickname',
      validation: 'Nickname must be 3 to 16 characters long and contain at least 1 letter.',
      terms: 'By clicking the "Next" button below you are accepting our <0>terms and conditions</0>, our <1>privacy policies</1> and that you are above 13 years old.',
      haveAccount: 'Already have an account? <0>Log in<0/>',
      enterEmail: 'Enter an e-mail and create your nickname',
    },
    creatorProfile: {
      content: 'Creator\'s content',
      notFound: 'We couldn\'t find any creators associated with this link',
    },
    deleteAccount: {
      title: 'Do you want to delete your account?',
      disclaimer: 'You will lose your Flixx, credentials and content',
      check: 'I agree to delete my account',
      accept: 'Delete account',
      toastMessage: 'Your account deletion request has been successfully registered',
    },
    referred: {
      flow: 'Referral',
      title: 'Were you invited to Flixxo?',
      description: 'Enter the nickname of the person who invited you, and <0>both of you will earn Flixx.</0>',
      placeholder: 'Nickname of who invited you',
      cta: 'Validate invitation',
      cta_loading: 'Validating invitation...',
      skip: 'Continue without an invitation',
      error: 'The data you entered does not match a user\'s nickname.',
      toastSuccess: 'Referral nickname successfully validated',
    },
  },
  moreFlixx: {
    title: 'Do you want more Flixx?',
    ad: 'Watch an ad and earn Flixx!',
    invite: 'Invite anyone you want and both get Flixx!',
    inviteToast: 'You\'ve copied the link correctly!\nShare it with the people you want to be part of Flixxo',
  },
  ad: {
    message: 'Earning flixx...',
  },
  adblock: {
    title: 'Hey, you got your ad blocker on?',
    description: ' Advertisers help keep the content free for you. Turn off the blocker and refresh the page.',
  },
  footer: {
    aboutUs: 'About us',
    flixx: 'What is a Flixx?',
    terms: 'Terms and conditions',
    privacy: 'Privacy policies',
    business: 'Business',
    contactUs: 'Contact us',
  },
  flixx: {
    title: 'Flixx is Flixxo\'s currency',
    description: 'With Flixx, you can watch microseries, short films, and support creators. We borrowed technology from Bitcoin to develop our own cryptocurrency, ensuring fair and transparent monetization. Flixx belongs to you, the creators, our entire community, and holds value both inside and outside our apps',
    imFlixx: 'Hi! I am Flixx',
    getFlixx_title: 'Many ways to snag Flixx',
    buy: {
      title: 'Buy Flixx from Flixxo',
      description: 'Crafted Flixx offers just for you, so you can savor the ultimate Flixxo experience',
      example_title: 'Want more Flixx?',
      example_description: 'Take advantage of the offers we\'ve prepared for you',
      example_cta: 'Buy',
    },
    ads: {
      title: 'Earn Flixx watching ads',
      description: 'You can earn Flixx by watching ads whenever you like, or before playing content if you don\'t have enough Flixx',
    },
    challenges: {
      title: 'Earn rewards by completing challenges',
      description: 'Complete the challenges we have prepared for you and earn generous rewards in Flixx (access the challenges <0>downloading our mobile app</0>)',
      example_title: 'The first of many to come...',
      example_description: 'As a welcome gift, Flixx for your first view',
      example_prize: 'Prize',
      example_cta: 'Claim reward',
    },
    exchanges: {
      title: 'Buy Flixx on an exchange',
      description: 'You can purchase and transfer Flixx to your wallet from <0>Uniswap</0> or <1>Bitmart</1>',
    },
    faqs: {
      title: 'More information',

      question_1: 'Once I’m registered, what do I need to watch content?',
      answer_1: 'It depends on how you joined the platform. If you have Flixxo through a mobile operator <high>(available in select countries)</high>, you can watch content anytime, anywhere. Otherwise, you\'ll need Flixx in your account. You can earn Flixx by watching ads or completing different challenges.',

      question_2: 'What is the price of Flixx?',
      answer_2: 'You can check Flixx price at <0>CoinMarketCap.</0>',

      question_3: 'Why does Flixxo use Flixx?',
      answer_3: 'By using our own cryptocurrency we were able to create an ecosystem where advertisers, audience and content creators can interact and pay each other in an straight and transparent way, without any intermediaries.',

      doubts: 'Any doubts? <0>contact us</0>',
    },
  },
  faq: {
    description: 'Answers to the most frequently asked questions.',

    whatIsFlixxo_question: 'What is Flixxo?',
    whatIsFlixxo_answer: 'Flixxo is a platform for microseries and short films from around the world. The distribution and monetization model on Flixxo is unlike anything else out there, as it allows:<bold>For viewers:</bold><li>To have an alternative to <high>discover new voices and independent, original content</high>, meaning content that doesn\'t rely on algorithms.</li><enter /><li>To watch good stories in a short amount of time.</li><bold>For creators:</bold><li>To <high>monetize instantly and transparently.</high></li>',

    whatContent_question: 'What kind of content can I find on Flixxo?',
    whatContent_answer: 'On Flixxo, you\'ll find a variety of content from emerging and independent creators that we\'ve discovered at festivals worldwide. Our curation focuses on new creative voices across all genres.<enter />Our content is divided into Short Films and Microseries, perfect for viewing at any time and place due to their short duration ;)',

    howAccessContent_question: 'How do I access Flixxo content?',
    howAccessContent_answer: 'Easy! Just go to the website and click the "Log In" button at the top right, then follow the instructions. You can also download the Flixxo app, available on both <android>Android</android> and <ios>iOS</ios>.',

    canUploadMyContent_question: 'I\'m creator. Can I upload my content to Flixxo?',
    canUploadMyContent_answer: 'Absolutely! Check out <a>Flixxo Creators</a> for all the details.',

    flixxoDifference_question: 'What makes Flixxo different from other platforms?',
    flixxoDifference_1: 'Short-term content',
    flixxoDifference_2: 'Transparent and direct support to the content creator',
    flixxoDifference_3: 'A diverse range of voices from emerging creators',
    flixxoDifference_4: 'On Flixxo, content visibility is based on curation, not an algorithm',
    flixxoDifference_5: 'Creative ecosystem between creators and audience',

    producing_question: 'Flixxo produces?',
    producing_answer: 'Yes! As a production house, Flixxo develops its own original content called <high>Flixxo Originals</high>.\n\nPlus, as a distributor through <high>XO Distribution</high>, Flixxo supports over 30 <high>"Creators 3.0"</high> from around the world, promoting their stories in international markets and on other platforms.',

    creators3_question: 'What are the Creators 3.0?',
    creators3_1: 'Creators 3.0 are the protagonists of a new paradigm of collaborative and decentralized creativity, typical of the technology and culture of web 3.0.',
    creators3_2: 'The fundamental value of web 3.0 is decentralization, that is, that there is no intermediary setting the rules. This gives the power back to the content creator to monetize directly.',

    doubts: 'Do you have any doubts? <mail>contact us</mail>',
  },
  beCreator: {
    title: 'Let\'s promote your content\n<high>within your community</high>',
    description: 'We support independent creators from all around the world during the production and distribution of their content.',
    cta: 'I want to be a creator 3.0',
    yourContent: 'Your content',
    benefits: {
      global: '<high>Microseries and short films</high> from anywhere in the world.',
      independent: '<high>Independent creators</high> who dare to disrupt the paradigm.',
      diverse: '<high>Diverse contents</high> covering all kinds of subjects and genres.',
      advice: '<high>You can count on our advice</high> to improve your content\'s quality.',
      reach: '<high>We can expand your reach</high> in order to get to new markets and platforms.',
      rules: '<high>Your content, your rules.</high> We are here to stand by your vision.',
    },
    steps: {
      title: 'Flixxo welcomes you with open arms',
      description: 'We understand the value and potential independent content holds, that is why the first thing you\'ll receive from us is a toast and a hug to welcome your series. From that moment on, our goal is to stand by your content.',
      reachUs: {
        title: 'Contact us',
        description: 'Show us your content',
        example_time: '{{number}} min. ago',
        example_creator_chat: 'Hello, Flixxo! I have a microseries and a short film that I would like to publish on your platform 🤍',
        example_flixxo_chat: 'Hello, creator, that\'s awesome! We love getting proposals. Let\'s work together to publish the content on Flixxo as soon as possible.',
      },
      publish: {
        title: 'We publish your content',
        description: 'Once we receive the content, we work together to publish it',
      },
      monetize: {
        title: 'Monetize with every purchase',
        description: 'Every time someone buys your content, you earn Flixx. You receive it directly, no middlemen involved',
      },
    },
    protagonist: {
      title: 'Become a Creator 3.0',
      description: 'Be at the center of your content and your community.',
    },
    dashboard: {
      title: 'Each time someone access your content, you earn Flixx.',
      description: 'Flixx is Flixxo\'s cryptocurrency. Each time someone accesses your content you\'ll receive Flixx directly into your account.',
      content_description: 'Follow up on your content\'s interactions in a detailed manner.',
      earnings: 'Your earnings',
      earnings_description: 'Review and filter the evolution of your earnings.',
    },
    footer: {
      title: 'Are you ready to become a Creator 3.0?',
      faq: 'Any questions? Most likely they are covered in our <0>faq</0>',
    },
  },
  tokenGating: {
    seo_description: 'Token Gating experience on Flixxo. Connect your wallet and unlock access to unique content with valid tokens',
    description: 'If you have a specific token, you can unlock unique content',
    description2: 'Connect your wallet and discover them',
    cta: 'Connect Wallet',
    walletConnected: 'Wallet connected',
    walletDisconnected: 'Wallet disconnected',
    connect: 'Connect',
    disconnect: 'Disconnect',
    step1: 'Get the token',
    step2: 'Connect your wallet',
    step3: 'Watch content',
    availableWithToken: 'Available with token',
    needToConnectWallet: 'You need to connect your wallet',
    whichTokensNeed: 'Which token/ticket 3.0 you need?',
    haveToken: 'You got your token/ticket 3.0!',
    waitingSignature: 'Waiting signature...',
    validating: 'Validating...',
    faqs: {
      title: 'Frequent questions',
      question_1: 'What is Token Gating?',
      answer_1: 'Token Gating is a section of exclusive content on Flixxo. The contents of this section will be enabled only to users who have the required token. With Token Gating we find a new way to continue building more meaningful ties in the independent film community.',
      question_2: 'What do I need to access it?',
      answer_2: 'To access the exclusive content you need to have the corresponding token in the wallet you link to Flixxo. Hit play and our system will verify token ownership and enable playback.',
      question_3: 'How do I get tokens?',
      answer_3: 'Each exclusive content has its own token that enables it. You may have previously acquired the token, but in the prelude to the content we will tell you where to get it.',
      question_4: 'I already have the token... now what?',
      answer_4: 'Log in to Flixxo and link the wallet where you have the token... and that\'s it! You can now enjoy the exclusive content.',
      question_5: 'Can I access content without the token?',
      answer_5: 'If you have a Flixxo account, you can now access +300 content through Flixx in your balance. If you don\'t have Flixx, watch an ad first. You can only see the exclusive content of this section with the corresponding external token.',
      question_6: 'How do I connect my wallet?',
      answer_6: 'Connect your wallet in this same section, from the Connect wallet button. Choose the wallet and enter your credentials. Once connected, you will see the corresponding indicator. You can disconnect it at any time.',
    },
    error: {
      alreadyUsed: 'The wallet is already associated to another account',
    },
  },
  vas: {
    loginModal: {
      title: 'Thank you for being part of Flixxo!',
      description: 'To log in with your Flixxo Prepaid account, contact your mobile operator, and they will provide you with access. We\'re looking forward to seeing you!',
    },
  },
  privacy: {
    title: 'Privacy Policy',
    date: '<b>Effective Date:</b> March 22, 2019',
    item_1: {
      title: '1. Introduction',
      p1: 'We, Flixxo, are a decentralized video distribution platform based on blockchain and bit torrent technology. Flixxo is operated by Flixxo Limited, a Gibraltar company. Our address is Suite 23, Portland, House Glacis Road, Gibraltar. Our mission is to enable any Internet user to become a video distributor, help content authors monetize their production by receiving payment directly from consumers and lead advertisers to have a closer knowledge and relation with their targets. Our primary goal is to contribute to changing the market as we know it today, by eliminating intermediates and favoring peer to peer connections.',
      p2: 'We collect your personal information in order to enable your use of the Platform and the Sites, share relevant news and information and maintain a vibrant community of Users worldwide (our “Purpose”). This privacy policy (the “Privacy Policy” or this “Policy”) governs the way we collect, use and disclose your personal information worldwide.',
      p3: 'This Policy is also governed by the Terms and Conditions available on our Site. The terms in caps in this document follow the definitions provided in our Terms and Conditions.',
    },
    item_2: {
      title: '2. Scope of this Policy',
      p1: 'This Policy applies to the processing of all Personal Information, as defined below, regarding all Users, including all individuals that register on our Platform.',
      p2: 'While this Policy is intended to describe the widest range of our information processing activities globally, those activities may be more limited in some jurisdictions based on the restrictions of their laws. For example, the laws of a particular jurisdiction may limit the sorts of personal information we can collect or the ways in which we process that personal information. In those cases, we adjust our internal practices and policies in order to comply with the applicable laws.',
    },
    item_3: {
      title: '3. Personal Information We Process',
      p1: 'The term <b>“Personal Information”</b> in this Policy refers to information that identifies you or that is capable of identifying you as an individual. The types of personal information that we process (which may vary by jurisdiction based on applicable law) may include:',
      p2: 'You can always visit our Sites without logging in or revealing who you are. Most of the personal information that we use is information that you provide to us. However, in other instances, we process personal information that we are able to infer about you based on other information you provide to us or during our interactions with you, or personal information about you that we receive from a third party. This Policy also applies to the information about you that we receive from others, and which may also be subject to additional privacy policies from third parties.',
      ul1: 'Your first and last name, email, mobile phone, address, image, locational data;',
      ul2: 'Information, feedback or content you provide regarding your contact and subscription preferences;',
      ul3: 'Information collected when you access our Platform or one of our Sites, including the IP address automatically assigned to your computer, the date and time of your visits, the sections you access and the amount of time you spend on each section, the Content you open, the type of device, browser and operating system that you use, and the URL of any websites that you visited before and after visiting our Platform or our Sites;',
      ul4: 'Information collected when you communicate with us, including emails, messages and logs;',
      ul5: 'Information, feedback or content you provide in our Platform and our Sites and those of third parties, including publicly available social media profiles, status and information;',
      ul6: 'Your Flixx activity and all movements in your digital wallet.',
    },
    item_4: {
      title: '4. Sensitive Personal Information',
      p1: 'Some of the personal information that we process is considered Sensitive Personal Information under the privacy laws of some jurisdictions. Such laws define <b>“Sensitive Personal Information”</b> as personal information from which we can learn or infer an individual\'s political participation, voting, opinions, religious beliefs or other beliefs of a similar nature, philosophical beliefs; trade union membership or membership of a professional association, physical or mental health conditions, medical treatments, genetic data, sexual life or judicial data (including information concerning the commission or alleged commission of a criminal offence). In some particular instances, financial records may form part of Sensitive Personal Information where you are located. We only process Sensitive Personal Information in your jurisdiction if and to the extent permitted or required by applicable law.',
      p2: 'When you agree to this Policy you are, to the extent permitted under applicable laws, granting your express and written consent to the processing of any personal information that you provide to us that is considered to be Sensitive Personal Information. Except to the extent required by law, you may refuse to provide us with any of your Sensitive Personal Information, and this will not affect your participation in our activities or your engagement in our Platform or our Sites.',
    },
    item_5: {
      title: '5. How We Process Your Personal Information',
      p1: 'Subject to applicable laws, we may process, collect, use and disclose your personal information in order to:',
      p2: 'All processing of your personal information is founded on a lawful basis for processing. In most instances, processing will be justified on the basis that: (a) the processing is in Flixxo’s legitimate interests, subject to your interests and fundamental rights; (b) the processing is necessary for the performance of a contract to which you are a party, or to take steps (at your request) to enter into a contract; (c) the processing is necessary for us to comply with a relevant legal obligation (for instance, where we are required to make disclosures by applicable laws); (d) the processing is necessary for the performance of a task carried out in the public interest (including the protection of human rights). In some circumstances, we will use your consent as the basis for processing your personal information, for example, where we are required by law to obtain your prior consent in order to communicate with you.',
      p3: 'Before collecting and/or using any Sensitive Personal Information, we will establish a lawful exemption which will allow us to use that information. This exemption will typically be your explicit consent; the establishment, exercise or defense by us or third parties of legal claims; or a context specific exemption provided for under local laws.',
      p4: 'We hold your personal information for as long as is necessary to fulfill our Purpose or for a longer period as may be required by applicable laws.',
      ul1: 'Contact you and send you relevant news and updates on our activities;',
      ul2: 'Provide you with a better or more personalized contact, including news and information from a third party (such as sponsors and business partners), and engaging with you across different connectivity platforms and social media channels;',
      ul3: 'Conduct research, supporter engagement surveys and audits;',
      ul4: 'Request, process and manage Flixx payments;',
      ul5: 'Respond to requests for information, including services from a third party (such as sponsors, business partners or other virtual wallets that you authorize);',
      ul6: 'Provide for the safety and security of our Users and equipment;',
      ul7: 'Manage and keep our records, perform monitoring and training, facilitate audits and comply with legal and regulatory requirements.',
    },
    item_6: {
      title: '6. Transferring and Disclosing your Personal Information',
      p1: 'In some circumstances, we may transfer or disclose your personal information. We will always make such transfer and disclosures under the requirements of all applicable laws. In some jurisdictions, data protection laws may require us to obtain your consent before we transfer your information from your home country to other jurisdictions. When you agree to this policy, you are, to the extent required and permitted under your applicable laws, granting your active consent to the transfer of your personal information to such other jurisdictions for the purpose and with the scope stated in this Policy.',
      p2: 'We may transfer and disclose personal information in situations which include:',
      p3: '<b>Our Employees, Partners, Agents, Contractors and Service Providers.</b> We may engage employees, partners, agents, contractors and service providers to assist us in contacting Users for the purpose of managing our supporter contact, events and other activities. Like many other entities, we may outsource the processing of certain activities and/or information to third parties. We take reasonable steps designed to ensure that our employees, partners, agents, contractors and service providers engaged in processing personal data do so under written confidentiality agreements, have received the appropriate training to handle personal data, and are informed of the confidential nature of such personal data. When we do outsource the processing of your personal information to third parties or provide your personal information to third-party service providers, we oblige those third parties to protect your personal information in accordance with the terms and conditions of this Policy, with adequate security measures.',
      p4: 'We use Amazon Web Services to run our sites. These service providers and other third parties, including Google, may use cookies to provide a better navigation experience. You can disable these specific cookies that track prior visits for the sake of follow-up advertising by opting out at <googlePolicies>https://policies.google.com/technologies/ads</googlePolicies>.',
      p5: '<b>Legal Requirements; Compliance with Laws and Law Enforcement.</b> We cooperate with government entities and private organizations to enforce and comply with the law. We reserve the right to share any personal information we have concerning you if we are compelled to do so by a court of law or lawfully requested to do so by a governmental entity. We may also disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal processes, to comply with the law, to protect the safety of any person, or to prevent or stop any activity we may consider to be illegal, unethical or legally objectionable. We also reserve the right to retain personal information collected and to process such personal information to comply with accounting and tax rules and regulations and any specific record retention laws.',
      p6: 'In case that the user wishes to delete his account and delete his personal data, can be requested to <mailto>info@flixxo.com</mailto>',
    },
    item_7: {
      title: '7. Aggregate Information and Non-Identifying Information',
      p1: 'We may share aggregated information that does not include personal information and we may otherwise disclose non-identifying information and log data with third parties for supporter analysis, general audits and reports, demographic profiling and other purposes. Any aggregated information shared in these contexts will not contain your personal information.',
    },
    item_8: {
      title: '8. Cookies',
      p1: 'We may use cookie technology on our Platform and our Sites to allow us to audit and improve the activities of our Platform and our Sites. Cookies on their own cannot be used to reveal your personal information. They identify your browser, but not you, to our servers when you visit our Sites. If you do not want to accept cookies, you can block them by adjusting the settings on your browser. However, if you block them, you will not be able to use all of the features of our websites.',
    },
    item_9: {
      title: '9. Use of Your Personal Information in Social Media Platforms and Other Websites',
      p1: 'Our Platform and our Sites may also contain plug-ins and connectivity features that integrate third party social media platforms into our Sites. If you use such plug-ins the third parties who operate these platforms may be able to identify you, they may be able to determine how you use this website and they may link and store this information with your social media profile. Please refer to the data protection policies of these social media platforms to understand what they will be doing with your personal information.',
      p2: 'Users who decide to withdraw Flixx to other virtual wallets outside of Flixxo will be subject to such virtual wallet’s terms and privacy policies. This Policy, and our responsibility, is limited to our own information processing practices. We are not responsible for, and cannot always ensure, the information processing practices or privacy policies of other wallets, platform or sites where you submit your personal information directly to them. In addition, we cannot ensure the content of the sites maintained by these third parties or our service providers, even if accessible using a link from our Platform or our Sites. We pledge you to read the privacy and security policies of any third-party wallets, platforms and sites before providing any personal information while accessing those sites.',
    },
    item_10: {
      title: '10. Security',
      p1: 'The security of your personal data is important to us. We use Secured Socket Layer (“SSL”) software in order to encrypt the personal information that you provide to us. We follow generally accepted standards to protect the personal data submitted to us. Nevertheless, we are aware that no method of transmission over the Internet, or method of electronic storage, is 100 percent secure and we cannot guarantee its absolute security.',
      p2: 'The personal information we collect from you online is stored by us and our service providers on databases protected through a combination of access controls, security technology and other state-of-the-art security measures. Nevertheless, such security measures cannot always prevent every loss, misuse or unauthorized changes of personal information and to the fullest extent permitted by applicable law, we are not responsible for any damages or liabilities relating to any such incidents.',
      p3: 'We maintain an internal data security policy, which incorporates technical, administrative, and physical security measures designed to ensure the safety and integrity of personal data. Interested individuals may request a copy of our data security documentation, which we will provide under a confidentiality agreement.',
      p4: 'We will inform you, as legally required, of any breach of the security, confidentiality, or integrity of personal data stored by us in the quickest fashion and without unreasonable delay. We will take all measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system so that you can take the appropriate actions for the due protection of your rights.',
    },
    item_11: {
      title: '11. Your Rights',
      p1: 'You may always choose what personal information you wish to provide to us. However, if you choose not to provide certain details, some of your experiences with us may be affected. For example, you may not be able to sign a petition without providing your name.',
      p2: 'We are committed to respecting your rights over your personal data, including the following rights:',
      p3: 'If you would like to exercise any of the above rights, please contact us here so that we may consider your request in accordance with applicable law.',
      p4: 'If you want to obtain information about any personal data that we may have about you, you can do so by sending us a written request by letter or email to the addresses set out in the “Contact Us” section below. Please be sure to include your full name, address and telephone number and a copy of a document to prove your identity (such as an ID card or passport) so we can confirm your identity and whether we have any personal information about you, or in case we need to contact you to obtain any additional information to process your request.',
      p5: 'We reserve the right to charge you a fee, when permitted under applicable law, for processing any such request. If you send us more than one request, we may respond to your later request by referring to our first response and only identifying any items that have changed. You may request that we correct, erase and stop processing personal information that we hold about you, and we will proceed accordingly as per the applicable law.',
      ul1: '<b>Right of Access:</b> You may have the right to access the personal data that you have provided us.',
      ul2: '<b>Right to Rectification:</b> You may have the right to require us to correct any of your personal data.',
      ul3: '<b>Right to Erasure:</b> You may have the right to the erasure of personal data that we hold about you.',
      ul4: '<b>Right to Restrict Processing:</b> You may have the right to request that we stop processing your personal data and to stop sending you messages.',
      ul5: '<b>Right to Object to Processing</b>, and right not to provide or to withdraw your consent at any time;',
      ul6: '<b>Right to Data Portability:</b> the right to receive your personal data concerning him or her, in a structured, commonly used and machine-readable format and have the right to transmit the data to another entity without hindrance;',
      ul7: '<b>Right to Decline Automated Decision Making:</b> the right to not be subject to decisions based solely on automated decision making, which produce legal or significant effects for you, except where these are (i) necessary for a contract to which you are a party; (ii) authorized by law; (iii) based on your explicit consent. We currently do not use automated decision making (including automated decision-making using profiling) when processing your personal information. If we ever use an automated decision-making solution, you have a right to request that a decision based on your personal information cannot be solely decided via an automated process;',
      ul8: '<b>Right to lodge a complaint</b> with a supervisory authority, designated by applicable law.',
    },
    item_12: {
      title: '12. Changes to this Policy',
      p1: 'We may update this Policy from time to time. In such cases, we will post the current version on our main Site, and we will revise the version date located at the top of this document. We encourage you to periodically review this Policy.',
    },
    item_13: {
      title: '13. Contact Us',
      p1: 'We have appointed a Data Privacy Officer who may be reached at <mailto>legal@flixxo.com</mailto>.',
      p2: 'Our main address is Suite 23, Portland, House Glacis Road, Gibraltar.',
      p3: 'All requests for access to your personal information must be submitted in writing by letter, form or email and must include a copy of an identity document. We may respond to your request by email, phone or any other suitable method.',
    },
  },
  old: {
    /**
     * COMMON
     */
    goToHome: 'Go to home',
    spanish: 'Spanish',
    english: 'English',
    enterEmail: 'Enter your e-mail',
    creators: 'Creators',
    download: 'Download',
    downloadApp: 'Download app',
    back: 'Go back',
    series: 'SERIES',
    min: 'min',
    faqs: 'FAQs',
    o: 'or',
    accept: 'Accept',
    language: 'Language',
    content: 'Content',

    /**
     * MODAL
     */
    modal_enjoyYourContent_title: 'Enjoy your content',
    modal_enjoyYourContent_content: 'Get to know all the features in our app',
    modal_spendFlixx_title: 'Spend your Flixx',
    modal_spendFlixx_content: 'See the content you really want to see.',
    scanCodeQR: 'Scan the QR code and access Flixxo',
    fromYourMobilePhone: 'from your mobile phone!',
    availableOn: 'Available on',
    liveFullExperience: 'Live the full experience!',

    /**
     * NEW MESSAGES
     */
    retry: 'Retry',
    openApp: 'OPEN IN THE APPLICATION',
    backToPlay: 'Back to Play Flixxo',
    goToCreators: 'Go to Flixxo Creators',
    showDetail: 'Show detail',
    sendCode: 'Send code',
    enterPhone: 'Enter your phone number',
    enterCode: 'Enter here the code',
    newPassword: 'New password',
    confirmPassword: 'Confirm your password',
    didNotGetCode: 'Did not get the code?',
    sendAgain: 'Send again',
    enter: 'Enter',
    purchased: 'Purchased',
    watchFor: 'Watch for',
    watchSE: 'Watch S{{season}} E{{episode}}',
    audio: 'Audio',
    video: 'Video',
    videos: 'Videos',
    nextEpisode: 'Next episode',
    starting: 'Starting',
    continueWatching: 'Continue watching',
    didYouEnterWrongNumber: 'Did you enter a wrong number?',
    selectOption: 'Select an option',
    next: 'Next',
    enjoyFullExperience: 'Enjoy the full expererience with our app',
    open: 'Open',
    continueOnBrowser: 'Continue on browser',

    statistics: 'Statistics',
    newFollowers: 'New Followers',
    status: 'Status',
    releaseDate: 'Release Date',
    purchases: 'Purchases',
    earnings: 'Earnings',
    price: 'Price',

    /**
     * MODALS WELCOME, LOGIN, REGISTER, RECOVERY
     */
    login: 'Sign in',
    loggingIn: 'Signing in...',
    createAccount: 'Create account',
    creatingAccount: 'Creating account...',
    goodToSeeYouAgain: 'Good to see you again!👋',
    fillInYourDetails: 'Fill in your details to enter.',
    nicknameOrEmail: 'Nickname or e-mail',
    password: 'Password',
    forgotMyPassword: 'Forgot my password',
    firstTimeInFlixxo: 'First time in Flixxo?',
    createYourAccount: 'Create your account',
    recoverPassword: 'Recover password',
    recoveringPassword: 'Recovering password...',
    enterYourMobileNumber: 'Enter your mobile number',
    mobileNumber: 'Mobile number',
    mobileInfo: 'Your phone number is required as a second security factor so that you can recover your password.',
    passwordsDoesntMatch: "Passwords doesn't match",
    enterCodeAndCreateNewPwd: 'Enter the code we sent you and create the new password',
    resendCode: 'Resend code',
    repeatPassword: 'Repeat password',
    passwordReqs: 'It must contain at least one lowercase, one uppercase, and one number. At least 8 characters long, no more than 30 characters.',
    passwordRecoverySuccessfull: 'Password succesfully recovered!',
    nowYouCanLoginWithNewPwd: 'Now you can sign in with the new password',
    great: 'Great',
    email: 'E-mail',

    createPassword: 'Create a password',
    mustContainOneLowerCase: 'Must contain at least one lower case',
    mustContainOneUpperCase: 'Must contain at least one upper case',
    mustContainOneNumber: 'Must contain at least one number',
    lengthMinMax: 'Must be {{min}} to {{max}} characters long',
    congratsHaveCreatedAnAccount: 'Congrats! \n You have created an account',
    nowYouAreFlixxer: 'Now you are a Flixxer! 🤘',
    validateAccount: 'Validate account',
    validatingAccount: 'Validating account...',
    finishAccountValidation: 'Finish the validation of your account by entering your mobile number',
    enterCodeSentTo: 'Enter the code sent to:\n{{mobileNumber}}',

    recaptcha: 'This site is protected by reCAPTCHA and the Google <0>Privacy Policy</0> and <1>Terms of Service</1> apply.',

    /**
     * SEO
     */
    seoSeriesTitle: 'Series',
    seoSeriesDescription: 'Original short-form series from all around the world',
    seoSearchTitle: 'Search',
    seoSearchDescription: 'Search for the best Flixxo content & videos',
    seoDefaultTitle: 'Flixxo',
    seoDefaultDescription: 'Independent cinema and series in a web3 fashion',
    seoBeCreatorTitle: 'Be a creator',
    seoBeCreatorDescription: 'We support independent creators around the world in the production and distribution of their content.',
    seoFlixxTitle: 'Flixx, our cryptocurrency',
    seoFlixxDescription: 'Flixx is the cryptocurrency of Flixxo, use it to to watch microseries for free while supporting its creators at the same time.',

    /**
     * CREATORS
     */
    fromBeginning: 'From beginning',
    last30Days: 'Last 30 days',
    last7Days: 'Last 7 days',
    fromBeginningPhrase: 'Since you uploaded your first content',
    periodPhrase: 'From {{ start }} to {{ end }}',
    allContent: 'All content',

    noSearchResults: 'There are no results for your search',
    noSearchResultsDescription: 'Try searching another content',
    noContentYet: "You don't have any content yet",
    noContentYetDescription: 'Start to upload your content and create your own audience',
    noInternet: 'No internet connection',
    noInternetDescription: 'Check your connection and try again',
    serverError: 'Server error',
    serverErrorDescription: 'Please try again',

    searchYourVideo: 'Search your video...',
    viewOnFlixxo: 'View on Flixxo',
    published: 'Published',
    deleted: 'Deleted',
    paused: 'Paused',
    unknown: 'Unknown',

    seasonEpisode: 'S{{season}}E{{episode}}',
    earningsTooltip: 'Part of your income (purchases), is used to support the Community.',
    yourContentIsPaused: 'Your content is paused',
    knowMoreAboutContent: 'To know more about this content, contact us to content@flixxo.com',
    sendYourQuestion: 'Send your question',

    /**
     * ERROR MESSAGES
     */
    contentNotFound: 'The content you are looking for doesn\'t exist',
    wrongCredentials: 'Invalid nickname, e-mail or password.',
    emailRegistered: 'The e-mail entered is already registered.',
    emailInvalid: 'The e-mail entered has not a valid format.',
    nicknameRegistered: 'The nickname entered is already registered.',
    passwordsDontMatch: 'Passwords don\'t match',
    mobileNumberInvalid: 'The mobile number entered is not valid',
    mobileNumberRegistered: 'The mobile number entered is already registered',
    mobileNumberUnknown: 'The mobile number entered doesn\'t belong to any registered user',
    codeInvalid: 'The code entered is not valid',
    operationLimitExceeded: 'Tries limit was exceeded. Please try again later.',
    notPossibleLogInThisAccount: 'Is not possible to log in with this account.',

    /**
     * CONTENT PLAYER
     */
    seasonEpisodeHyphen: 'S{{season}}-E{{episode}}',
  },
}

export default en

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation } from '@tanstack/react-query';
import { useApi } from 'hooks';
import { useModalAuth, useStore } from 'stores';
import { GAHandler } from 'utils/GA';
var useLoginExternal = function () {
    var apiFetch = useApi().apiFetch;
    var _a = useModalAuth(), openReferred = _a.openReferred, openOnboarding = _a.openOnboarding;
    var _b = useStore(), setSecretRegister = _b.setSecretRegister, referrer = _b.referrer, setSecret = _b.setSecret;
    var url = '/auth/loginExternal';
    var mutationFn = function (data) { return apiFetch({
        url: "".concat(url, "/").concat(data.provider),
        method: 'POST',
        data: { code: data.token },
    })
        .then(function (res) { return (__assign(__assign({}, res.data), { status: res.status })); }); };
    var _c = useMutation({
        mutationFn: mutationFn,
        onSuccess: function (data, variables) {
            GAHandler("login_".concat(variables.provider));
            if (data.status === 201) {
                GAHandler('user_created', undefined, true);
                gtag_report_conversion === null || gtag_report_conversion === void 0 ? void 0 : gtag_report_conversion();
                if (referrer) {
                    setSecret(data.secret);
                    openOnboarding();
                }
                else {
                    openReferred();
                }
                setSecretRegister(data.secret);
            }
        },
        onError: function (_, variables) {
            GAHandler("login_".concat(variables.provider, "_error"));
        },
    }), mutateAsync = _c.mutateAsync, rest = __rest(_c, ["mutateAsync"]);
    return __assign({ loginExternal: mutateAsync }, rest);
};
export default useLoginExternal;
